const getAppErrorContainer = (appId: string) => {
  const root = document.getElementById('single-spa-error')

  if (!root) {
    return null
  }

  let appError = root.querySelector<HTMLElement>(`[data-error-app-id="${appId}"]`)

  if (!appError) {
    appError = document.createElement('div')
    appError.setAttribute('data-error-app-id', appId)
    root.appendChild(appError)
  }

  return { root, appError }
}

export const showError = (appId: string, error: unknown) => {
  const errorContainer = getAppErrorContainer(appId)

  if (!errorContainer) {
    return
  }

  const message = error instanceof Error ? error.message : String(error)
  errorContainer.root.style.display = ''
  errorContainer.appError.innerText = message
}

export const hideError = (appId: string) => {
  const errorContainer = getAppErrorContainer(appId)

  if (errorContainer) {
    errorContainer.appError.remove()

    if (errorContainer.root.children.length === 0) {
      errorContainer.root.style.display = 'none'
    }
  }
}
